import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import theme from './_theme'
import styles from './_styles'

import { Container, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import HeroOne from '@solid-ui-blocks/Educabot/Education/Hero/Block03'
import Simplify from '@solid-ui-blocks/Educabot/Education/Hero/simplify'
import Features from '@solid-ui-blocks/Educabot/Education/Features/Block05'
import WhyChooseUs from '@solid-ui-blocks/Educabot/Education/Features/features'
import Contact from '@solid-ui-blocks/Educabot/Education/Contact'

import Centralizes from '@solid-ui-blocks/Educabot/Education/Hero/centralize'
import Organize from '@solid-ui-blocks/Educabot/Education/Hero/organize'
import Incorporate from '@solid-ui-blocks/Educabot/Education/Hero/incorporate'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.sections}>
        <HeroOne content={content['heroThree']} reverse />
      </Box>
      <Box sx={styles.sections}>
        <Features content={content['features-solution']} />
      </Box>
      <Box sx={styles.sections}>
        <Centralizes content={content['centralizes']} />
      </Box>
      <Box sx={styles.sections}>
        <Organize content={content['organize']} reverse />
      </Box>
      <Box sx={styles.sections}>
        <Incorporate content={content['incorporate']} />
      </Box>
      <Box sx={styles.simplify}>
        <Simplify content={content['simplify']} reverse />
      </Box>
      <Box sx={styles.whyChooseUs}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Box>
      <Box sx={styles.contact}>
        <Contact content={content['formContact']} reverse />
      </Box>
        <Footer content={content['footer']} />


    </Layout>
  )
}

export const query = graphql`
  query homepageEducationBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/education", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
