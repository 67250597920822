/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import bgContact from '../../../../../../site/content/assets/flexiblocks/homepage/education/bg-contact.svg'

export default {
  header: {
    bg: '#27A8FF',
    pt: '5rem',
  },
  contact: {
    bg: '#27A8FF',
    // backgroundImage: `url(${bgContact})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: `center`,
    // backgroundSize: 'contain',
    // '@media (max-width: 765px)': {
    //   backgroundSize: 'cover',
    // }
  },
  sections: {
    bg: '#FFFFFF',
    pt: '10rem'
  },
  whyChooseUs: {
    bg: '#FFFFFF',
    pt: '5rem',
    pb: '5rem'
  },
  simplify: {
    bg: '#FFFFFF',
    pt: '5rem',
    pb: '5rem'
  },
  features: {
    bg: '#FFFFFF',
    pt: '1rem',
    paddingBottom: '5rem'
  },
  testimonials: {
    bg: '#FFFFFF',
    pt: '8rem'
  },
  trustUs: {
    bg: '#F7F8FC',
    pt: '5rem',
    pb: '5rem'
  },
  blog: {
    bg: '#FFFFFF',
    pt: '5rem',
    pb: '5rem'
  }
}
